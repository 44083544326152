import { useQuery } from '@tanstack/react-query';
import { fetchFromContentful } from '@ui/utils/contentful';
import { useMemo } from 'react';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export const useHiddenBanners = create(
  persist<{
    hiddenBanners: string[];
  }>(
    () => ({
      hiddenBanners: [],
    }),
    {
      name: '__HIDDEN_BANNERS__',
    },
  ),
);

const BannerQuery = `
{
  amplyBannerCollection(order: [sys_firstPublishedAt_ASC]) {
    items {
      title
      description
      url
    }
  }
}
`;

const fetchBanners = async () => {
  const items = (
    await fetchFromContentful<{
      amplyBannerCollection: {
        items: { title: string; description: string; url: string }[];
      };
    }>(BannerQuery)
  ).amplyBannerCollection.items;
  return items;
};

export const useBanners = () => {
  const { data } = useQuery(['useBanners'], fetchBanners);
  const { hiddenBanners } = useHiddenBanners();
  const banners = useMemo(() => {
    if (!data) return [];
    return data.filter(({ title }) => !hiddenBanners.includes(title));
  }, [data, hiddenBanners]);
  return banners;
};
