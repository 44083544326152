import { LandingBlockBorder } from '@amply-app/icons';
import { Box, Center, chakra, Icon, Image, Link } from '@chakra-ui/react';
import { useOnboardingModal } from '@ui/components/OnboardingModal/useOnboardingModal';
import { tokenPathMap } from '@ui/components/TokenInput/constants';
import { EXTERNAL_LINK } from '@ui/config/externalLinks';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

const ChakraSwiper = chakra(Swiper);

const Slides = [
  {
    title: 'Generate Passive Yield',
    description:
      'Unlock a stream of passive income when you deposit your tokens with us.',
  },
  {
    title: 'Access Instant Liquidity',
    description:
      'Leverage our competitive interest rates to access instant liquidity whenever you need it.',
  },
  {
    title: 'Earn Rewards',
    description:
      'Receive rewards for supplying to or borrowing from our lending pools.',
  },
];

export const Offer = () => {
  const openModal = useOnboardingModal((s) => s.openModal);
  return (
    <Box mt={25} mb={30} pos={'relative'}>
      <Icon
        as={LandingBlockBorder}
        pos={'absolute'}
        w={'197.5px'}
        h={'96.25px'}
        color={'primary.cta'}
        right={0}
        top={'-22px'}
        zIndex={-1}
      />
      <Icon
        as={LandingBlockBorder}
        pos={'absolute'}
        w={'197.5px'}
        h={'96.25px'}
        color={'primary.cta'}
        left={0}
        bottom={'-48px'}
        transform={'rotate(180deg)'}
        zIndex={-1}
      />
      <Center mb={6} textStyle={{ base: 'h3', desktop: 'landingDesktopTitle' }}>
        Earn While You Explore
      </Center>
      <Center mb={10}>
        {Object.values(tokenPathMap).map((tokenPath, index) => (
          <Image
            src={`/tokens/${tokenPath}.svg`}
            alt={tokenPath}
            key={tokenPath}
            boxSize={{ base: 9, desktop: '75px' }}
            ml={index === 0 ? 0 : -2}
          />
        ))}
      </Center>
      <ChakraSwiper
        slidesPerView="auto"
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        w={{ base: '90vw', desktop: '800px' }}
        h={'156px'}
        sx={{
          '.swiper-pagination-bullet': {
            background: 'primary.active_state',
            opacity: 1,
          },
          '.swiper-pagination-bullet-active': {
            background: 'primary.cta',
            width: '30px',
            borderRadius: '25px',
          },
          '.swiper-button-prev, .swiper-button-next': {
            display: {
              base: 'none',
              desktop: 'flex',
            },
            borderRadius: '50%',
            backgroundColor: 'primary.icon_bg',
            w: 7,
            h: 7,
            cursor: 'pointer',
            '&::after': {
              color: 'primary.cta',
              fontSize: 12,
            },
          },
        }}
      >
        {Slides.map((slide) => (
          <SwiperSlide key={slide.title}>
            <Center
              color={'primary.caution'}
              textStyle={{ base: 'body2', desktop: 'h2' }}
            >
              {slide.title}
            </Center>
            <Center
              m={'8px 28px 0'}
              textStyle={{ base: 'body1', desktop: 'body2' }}
            >
              {slide.description}
            </Center>
          </SwiperSlide>
        ))}
      </ChakraSwiper>
      <Center mt={10} gap={4} textStyle={'cta'} h={'30px'}>
        <Center
          color={'primary.layer1'}
          bg={'primary.cta'}
          h={'100%'}
          w={'108px'}
          cursor={'pointer'}
          onClick={openModal}
        >
          Onboarding
        </Center>
        <Center
          color={'primary.cta'}
          borderWidth={'1px'}
          borderColor={'primary.cta'}
          h={'100%'}
          w={'130px'}
          cursor={'pointer'}
          as={Link}
          href={EXTERNAL_LINK.gitbook.home}
          isExternal
        >
          Documentation
        </Center>
      </Center>
    </Box>
  );
};
