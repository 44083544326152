import { Box } from '@chakra-ui/react';

const StyledBackground = () => {
  return (
    <Box
      position={'absolute'}
      zIndex={-1}
      w={'100%'}
      right={0}
      background={{
        base: "top / cover no-repeat url('/images/bg-landing-mobile.png')",
        desktop: "top / cover no-repeat url('/images/bg-landing-desktop.png')",
      }}
      h={{ base: '500px', desktop: '750px' }}
      top={0}
      opacity={{ base: 0.5 }}
    />
  );
};

export default StyledBackground;
