import { Box, Center, Flex, Image, Text } from '@chakra-ui/react';

export const AmplyToken = () => {
  return (
    <>
      <Flex gap={2.5} mb={4} alignItems={'center'}>
        <Text textStyle={{ base: 'h3', desktop: 'landingDesktopTitle' }}>
          Introducing $AMPLY
        </Text>
        <Box flex={1} pos={'relative'} h={'1px'} bg={'primary.active_state'}>
          <Box
            pos={'absolute'}
            bg={'primary.active_state'}
            h={'7px'}
            w={'65px'}
            left={0}
            top={'-3px'}
          />
          <Box
            pos={'absolute'}
            bg={'primary.active_state'}
            h={'7px'}
            w={'10px'}
            right={0}
            top={'-3px'}
          />
        </Box>
      </Flex>
      <Text mb={6} textStyle={{ base: 'body1', desktop: 'h3' }}>
        {`
       Stake $AMPLY, our native token, and you'll be entitled to amplified rewards.
        `}
      </Text>
      <Center position={'relative'} mb={26} mx={'-16px'}>
        <Image src={'/images/amply-token.svg'} alt={'apmly token'} />
        <Box
          zIndex={-1}
          position={'absolute'}
          width={'100%'}
          height={{ base: '38vh', desktop: '48vh' }}
          top={{ base: '-22px', desktop: '120px' }}
          background={{
            base: "center / contain no-repeat url('/images/bg-amply-token-mobile.svg')",
            desktop:
              "center / contain no-repeat url('/images/bg-amply-token-desktop.svg')",
          }}
        />
      </Center>
    </>
  );
};
