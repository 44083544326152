import { ExpandRight } from '@amply-app/icons';
import { Center, Flex, Icon, Link, Text } from '@chakra-ui/react';
import {
  useAmplyEventTimes,
  useClaimableAmount,
  useConvertibleAmount,
} from '@ui/hooks/data';
import { useTokenUnlockCountdown } from '@ui/hooks/useTokenUnlockCountdown';
import { Fraction } from 'bi-fraction';
import { format } from 'date-fns';

import { FloatingBox } from '../FloatingBox';
import TokenImage from '../Markets/IndividualMarket/TokenImage';
import { InDesktop, InMobile } from '../MobileOrDesktop';
import { Num } from '../Num';
import { Info } from '../TextBlocks';

export const ClaimAmplyEntry = () => {
  const { data: claimableAmount = Fraction.ZERO } = useClaimableAmount();
  const { data: convertibleAmount = Fraction.ZERO } = useConvertibleAmount();
  const today = new Date();
  const timer = useTokenUnlockCountdown();
  const { data: times } = useAmplyEventTimes();

  if (!times) return;

  const shouldDisplay =
    times.claimOpenAt <= today && today <= times.claimCloseAt;

  if (!shouldDisplay) return;

  const renderContent = () => {
    if (
      claimableAmount.isZero() &&
      !convertibleAmount.isZero() &&
      today < times.vestStartAt
    ) {
      return <Text textStyle={{ base: 'body3', desktop: 'h4' }}>{timer}</Text>;
    }
    return (
      <>
        <Num value={claimableAmount} />
        <TokenImage symbol={'AMPLY'} />
        <InDesktop>
          <Icon
            as={ExpandRight}
            color={'primary.cta'}
            boxSize={'32px'}
            ml={2}
          />
        </InDesktop>
      </>
    );
  };

  return (
    <>
      <Flex
        as={Link}
        href={'/claim'}
        gap={2}
        height={{ base: '50px', desktop: '80px' }}
        mb={{ base: 2, desktop: 3 }}
        cursor={'pointer'}
        justifyContent={'center'}
      >
        <FloatingBox
          as={Center}
          flex={1}
          borderRadius={'160px'}
          px={'18px'}
          maxW={'360px'}
        >
          <Info
            width={'full'}
            title={<Text textColor={'primary.text2'}>Claim $AMPLY</Text>}
            content={renderContent()}
          />
        </FloatingBox>
        <InMobile>
          <FloatingBox as={Center} w={'50px'} h={'100%'} borderRadius={'22px'}>
            <Icon as={ExpandRight} color={'primary.cta'} boxSize={6} />
          </FloatingBox>
        </InMobile>
      </Flex>
      {times.vestEndAt < today && today < times.claimCloseAt && (
        <Center
          height={7}
          color={'primary.error'}
          textStyle={{ base: 'body3', desktop: 'body2' }}
        >
          Claim AMPLY amount by {format(times.claimCloseAt, 'dd/MM/yyyy')}
        </Center>
      )}
    </>
  );
};
