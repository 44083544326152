import { intervalToDuration } from 'date-fns';
import { useEffect, useState } from 'react';

import { useAmplyEventTimes } from './data/useAmplyVesting';

export const useTokenUnlockCountdown = () => {
  const [timer, setTimer] = useState('');
  const { data: times } = useAmplyEventTimes();
  useEffect(() => {
    if (!times) {
      return;
    }
    const intervalId = setInterval(() => {
      const duration = intervalToDuration({
        start: new Date(),
        end: times.vestStartAt,
      });
      const [days, hours, minutes] = [
        duration.days,
        duration.hours,
        duration.minutes,
      ].map((v) => String(v ?? 0).padStart(2, '0'));
      setTimer(`${days} Days, ${hours}h : ${minutes}m`);
    }, 1_000);
    return () => clearInterval(intervalId);
  }, [times]);
  return timer;
};
