import { ExternalLink } from '@amply-app/icons';
import { Box, Icon, Link, Text } from '@chakra-ui/react';
import { EXTERNAL_LINK } from '@ui/config/externalLinks';

export const Audits = () => {
  return (
    <Box>
      <Text textStyle={{ base: 'h3', desktop: 'landingDesktopTitle' }}>
        Audits
      </Text>
      <Link
        href={EXTERNAL_LINK.gitbook.audit}
        textStyle={'body1'}
        mt={4}
        isExternal
      >
        Audited by &nbsp;
        <Text
          as={'span'}
          fontWeight={'700'}
          fontSize={'14px'}
          color={'primary.cta'}
          cursor={'pointer'}
          display={'inline-flex'}
          alignItems={'center'}
        >
          SlowMist
          <Icon as={ExternalLink} w={6} h={6} />
        </Text>
      </Link>
    </Box>
  );
};
