import { CONTENTFUL_URL } from '@ui/config/api';

const getContentfulHeader = (preview: boolean) => {
  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${
      preview
        ? process.env.CONTENTFUL_PREVIEW_ACCESS_TOKEN
        : process.env.CONTENTFUL_ACCESS_TOKEN
    }`,
  };
};

interface GraphQLResponse<T> {
  data: T;
  errors?: { message: string }[];
}

export interface ContentfulAsset {
  url: string;
  title: string;
  description: string;
}

export async function fetchFromContentful<T>(
  query: string,
  variables?: Record<string, unknown>,
  preview = false,
): Promise<T> {
  const response = await fetch(CONTENTFUL_URL, {
    method: 'POST',
    headers: getContentfulHeader(preview),
    body: JSON.stringify({ query, variables }),
  });
  const json: GraphQLResponse<T> = await response.json();
  if (json.errors) {
    const message = json.errors.map((error) => error.message).join(', ');
    throw new Error(`GraphQL Error: ${message}`);
  }

  return json.data;
}
