import { CardBorder, Close } from '@amply-app/icons';
import {
  Box,
  Button,
  chakra,
  Flex,
  Icon,
  Link,
  Text,
} from '@chakra-ui/react';
import { useBanners, useHiddenBanners } from '@ui/hooks/data/useBanners';
import { type PropsWithChildren } from 'react';
import { Autoplay, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { InDesktop, InMobile } from '../MobileOrDesktop';

const BannerCardWithBorder = ({ children }: PropsWithChildren) => (
  <Box
    height={'116px'}
    pos="relative"
    border={'0.5px solid rgba(253, 243, 167, 0.3)'}
    background={'primary.card_bg'}
    top={'2px'}
    mx={'2px'}
    padding={{ base: '12px 8px 8px 12px', desktop: '8px 10px 22px 26px' }}
    backdropFilter={'blur(2px)'}
  >
    <Box
      pos="absolute"
      pointerEvents={'none'}
      right={'-6px'}
      top={'-2px'}
      zIndex={-1}
    >
      <Icon as={CardBorder} w="102px" h="75px" color={'primary.cta'} />
    </Box>
    <Box
      pos="absolute"
      pointerEvents={'none'}
      left={'-6px'}
      bottom={'-2px'}
      transform={'rotate(180deg)'}
      zIndex={-1}
    >
      <Icon as={CardBorder} w="102px" h="75px" color={'primary.cta'} />
    </Box>
    {children}
  </Box>
);

const ChakraSwiper = chakra(Swiper);

const Banners = () => {
  const { hiddenBanners } = useHiddenBanners();
  const banners = useBanners();
  return (
    Boolean(banners.length) && (
      <ChakraSwiper
        slidesPerView={1}
        spaceBetween={'16px'}
        autoplay={{ delay: 5000, disableOnInteraction: true }}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination, Autoplay]}
        w={{ base: '340px', desktop: '560px' }}
        h={{ base: '142px', desktop: '158px' }}
        ml={{ desktop: 0 }}
        mb={5}
        sx={{
          '.swiper-pagination-bullets': { bottom: 0 },
          '.swiper-pagination-bullet': {
            background: 'primary.active_state',
            opacity: 1,
          },
          '.swiper-pagination-bullet-active': {
            background: 'primary.cta',
            width: '30px',
            borderRadius: '25px',
          },
        }}
      >
        {banners.map((banner) => (
          <SwiperSlide key={banner.title}>
            <BannerCardWithBorder>
              <Flex justifyContent={'space-between'}>
                <Text pt={1} textStyle={'h4'}>
                  {banner.title}
                </Text>
                <Icon
                  as={Close}
                  boxSize={6}
                  color={'primary.cta'}
                  cursor={'pointer'}
                  onClick={() => {
                    useHiddenBanners.setState({
                      hiddenBanners: [...hiddenBanners, banner.title],
                    });
                  }}
                />
              </Flex>
              <Flex justifyContent={'space-between'} mt={1}>
                <Text
                  textStyle={{ base: 'body3', desktop: 'body1' }}
                  color={'primary.text2'}
                  w={'307px'}
                  h={'42px'}
                >
                  {banner.description}
                </Text>
                <InDesktop>
                  <Button as={Link} href={banner.url} isExternal>
                    Take me there
                  </Button>
                </InDesktop>
              </Flex>
              <InMobile>
                <Flex justifyContent={'end'}>
                  <Button
                    variant={'link'}
                    as={Link}
                    href={banner.url}
                    isExternal
                  >
                    Take me there
                  </Button>
                </Flex>
              </InMobile>
            </BannerCardWithBorder>
          </SwiperSlide>
        ))}
      </ChakraSwiper>
    )
  );
};

export default Banners;
