import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  ListItem,
  OrderedList,
  Text,
} from '@chakra-ui/react';

const Questions = [
  {
    title: 'What is Amply Finance?',
    description:
      'Amply Finance is a peer-to-pool lending protocol deployed on the Cronos zkEVM chain. You can deposit your crypto into our lending pools to earn interest or use these assets as collateral to borrow other cryptocurrencies from the platform.',
  },
  {
    title: 'What is crypto lending and how does it work?',
    description:
      'Crypto lending is what happens when you lend your digital assets and earn interest on them. It works by depositing your crypto into a lending platform, such as Amply Finance. The platform then lends your assets to borrowers who need them, and you earn interest on your deposited assets as compensation. This provides an opportunity to grow your crypto holdings while helping others access the assets they need for various purposes.',
  },
  {
    title: 'Who can use Amply Finance?',
    description:
      'Amply Finance welcomes all users, from those new to the Cronos zkEVM ecosystem to the well-versed experts in the field.',
  },
  {
    title: 'Why should I use Amply Finance?',
    description: (
      <OrderedList>
        <ListItem>Access instant liquidity</ListItem>
        <ListItem>Earn additional yield on existing assets</ListItem>
        <ListItem>
          Share in the protocol’s potential upside with $AMPLY
        </ListItem>
      </OrderedList>
    ),
  },
  {
    title:
      'What are the interest rates and fees associated with using Amply Finance?',
    description:
      'We offer competitive interest rates and you can rest assured that there are no other fees or hidden charges associated with using Amply Finance.',
  },
  {
    title: 'How do I get started?',
    description: (
      <OrderedList>
        <ListItem>
          Create a Wallet: Set up a compatible crypto wallet that supports the
          Amply Finance platform.
        </ListItem>
        <ListItem>
          Deposit Assets: Deposit your desired assets into the Amply Finance
          lending pools to receive aTokens, which represent your deposited
          assets.
        </ListItem>
        <ListItem>
          Earn Interest: Begin earning interest on your deposits through the
          aTokens you receive.
        </ListItem>
        <ListItem>
          Borrow Assets: If needed, provide collateral and borrow assets from
          the lending pools.
        </ListItem>
        <ListItem>
          Manage Your Position: Keep an eye on your deposited assets, interest
          earned, and borrowed assets to start amplifying your success!
        </ListItem>
      </OrderedList>
    ),
  },
];

export const FAQSection = () => {
  return (
    <Accordion allowToggle px={4} mt={16}>
      {Questions.map(({ title, description }) => (
        <AccordionItem
          key={title}
          borderTop={0}
          borderBottom={'1px solid #3E5949'}
          pos={'relative'}
          mt={8}
          pb={2}
        >
          <AccordionButton p={0}>
            <Text textStyle={'h4'} flex={1} textAlign="left">
              {title}
            </Text>
            <AccordionIcon color={'primary.cta'} />
          </AccordionButton>
          <AccordionPanel color={'primary.text2'} p={'8px 0 0'}>
            {description}
          </AccordionPanel>
          <Box
            bg={'#3E5949'}
            pos={'absolute'}
            h={'2px'}
            w={'10px'}
            left={0}
            bottom={0}
          />
          <Box
            bg={'#3E5949'}
            pos={'absolute'}
            h={'2px'}
            w={'5px'}
            left={'50%'}
            bottom={0}
          />
        </AccordionItem>
      ))}
    </Accordion>
  );
};
