import type { BoxProps } from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';
import type { PropsWithChildren } from 'react';
import React from 'react';

export const FloatingBox = ({
  children,
  ...props
}: PropsWithChildren<BoxProps>) => {
  return (
    <Box
      background={'gradient.blur'}
      boxShadow={'0px 4px 4px 0px rgba(0, 0, 0, 0.25)'}
      {...props}
    >
      {children}
    </Box>
  );
};
